/*global fbq*/
import smoothscroll from 'smoothscroll-polyfill';
require('prismjs/themes/prism.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

smoothscroll.polyfill();

export const shouldUpdateScroll = ({ pathname, prevRouterProps }) => {
  // avoid scrolling to the top in infinite scroll for search
  if (pathname === prevRouterProps.location.pathname) return false;
};

export const onRouteUpdate = function () {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === 'function') {
    fbq("track", "ViewContent");
  }
};