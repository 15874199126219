module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P2R39NB "},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"tooltipText":"Copy to clipboard","toasterText":"Copied to clipboard","toasterDuration":4000},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_GB","url":"https://wttech.blog","site_name":"Wunderman Thompson Technology"},"twitter":{"site":"@WunThompsonTECH","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Wunderman Thompson Technology","short_name":"WTTech","description":"Tech news, open-source and stuff from Wunderman Thompson Technology.","lang":"en","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"static/img/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"phoneOnly":"(max-width: 639px)","phoneAndTablet":"(max-width: 767px)","phoneAndTabletExtended":"(max-width: 999px)","tabletUp":"(min-width: 1000px)","desktopUp":"(min-width: 1200px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
